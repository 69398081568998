declare var require: any
import { Component, OnInit,AfterViewInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from './../../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';
var moments = require('moment-timezone');
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
@Component({
  selector: 'app-messages',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './messages.component.html',
  styleUrls: ['../../../assets/css/min-bootstrap.min.css','../../../assets/css/font-awesome.min.css','../../../assets/css/chat.css','./messages.component.css'],
  
})
export class MessagesComponent implements OnInit,AfterViewInit {
  editID: any;
  messages: any=[];
  message: string = '';
  repMessage: any;
  friendsList: any=[];
  friendsList2: any=[];
  selectedFriendName: any;
  selectedUserID: any;
  selectedFriendId: any;
  loading: boolean = false
  UserId: any;
  UserName: any;
  isEmojiPickerVisible: boolean;
  fileName: any;
  file: File = null;
  msgCnt : any;
  msgType : any;
  config: any;
  adminId: any;
  loginid: any;
  convert_id : any;
  SockertURL:any=''
  endsess : any;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  snoozelength:number=0
  snoozelist:any=[]
  snoozedataobj:any={}
  enablesnooze:boolean=true
  @ViewChild('selctedsnoozeata') snoozeInput: ElementRef;
constructor( private _api: ApiService,private _auth: AuthService,private idle: Idle,private keepalive: Keepalive, private _router:Router,private spinner:NgxSpinnerService){
  this._api.getTypeRequest('user/getsettings').subscribe((res:any) => {
   idle.setIdle(res.data[0].idletime);
  });
  
}
showSnoozeLastDayOption: boolean = false;
selectedSnoozedate:any
showSnoozeList = false;

  toggleSnoozeListView() {
    this.showSnoozeList = !this.showSnoozeList;
    if(this.showSnoozeList){
      $('.showSnoozeList').show()
      $('#userlistview').addClass('userlistview')
    }else{
      $('.showSnoozeList').hide()
      $('#userlistview').removeClass('userlistview')
    }
  }
  getPSTTime() {



    //var options = { timeZone:'America/Los_Angeles',hour12: false};
    let date1 = new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles"
    });
    return date1
  }
  snoozepickeddatetime:any=''
  submitpck(){
    $('.DatepickerModel').hide();
    ////log(this.snoozepickeddatetime)
    this.selectedSnoozedate = moment($('#selctedsnoozeata').val()).format('YYYY-MM-DD HH:mm:ss');
    this.confirmsnoozeyes();
    this.snoozepickeddatetime=''
  }
  cancelpick(){
    $('.DatepickerModel').hide();
    this.snoozepickeddatetime=''
  }
  isOpenfordatepicker = () => {
    //settingsVal.offdays, settingsVal.timezone
    //settingsVal.starttime, settingsVal.endtime, settingsVal.timezone
    //.log(this.settingsVal)
    const now = moments(new Date()).tz(this.settingsVal.timezone);
    let currenttime=new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles"
    });
    //log(this.settingsVal.starttime,this.settingsVal.endtime)
    const storeOpenTime = moments.tz(now.format('YYYY-mm-dd')+' '+this.settingsVal.starttime, "YYYY-mm-dd H:mm:ss", this.settingsVal.timezone);
    const storeCloseTime = moments.tz(now.format('YYYY-mm-dd')+' '+this.settingsVal.endtime, "YYYY-mm-dd H:mm:ss", this.settingsVal.timezone);
    //log(now.format('ddd')+'=='+now.format('H:mm:ss')+'=='+storeOpenTime.format('ddd, H:mm:ss')+'==='+storeCloseTime.format('ddd, H:mm:ss'))
    //.log("storeOpenTime",storeOpenTime)
    //.log("storeCloseTime",storeCloseTime)
    return {starttime:moment(storeOpenTime).format('hh.mm'),endtime:moment(storeCloseTime).format('hh.mm')};
  }
  isOpenDayfordatepicker = (date) => {
    const now = moments(date).tz(this.settingsVal.timezone);
    var daySplit = this.settingsVal.offdays.split(",");
    //log(daySplit.indexOf(now.format('ddd')) !== -1) 
    return daySplit.indexOf(now.format('ddd')) !== -1;
  }
  getlimitTme(targetTime){
    const timeZone = "America/Los_Angeles";
    const [targetHour, targetMinute] = targetTime.split(":").map(Number);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); 
    const targetDate = new Date(currentDate.toLocaleString("en-US", { timeZone }));
    targetDate.setHours(targetHour, targetMinute);
    return targetDate.toLocaleTimeString("en-US", {timeZone,hour12: false,hour: "numeric",minute: "numeric"})
  }
  GetSnoozedateoption(day){
    const currentMoment = moment();
    if(day=='later'){
    let date  = moment().set({
        hour: 15,
        minute: 0,
        second: 0,
        millisecond: 0
      }).format('YYYY-MM-DD HH:mm:ss');
      return moment(date).format("MM/DD/YYYY hh:mm")
    }else{
    let date = moment(currentMoment).add(1, 'day').set({
        hour: 8,
        minute: 30,
        second: 0,
        millisecond: 0
      }).format('YYYY-MM-DD HH:mm:ss');
      return moment(date).format("MM/DD/YYYY hh:mm")
    }
    
  }
  SnoozedFor:any=''
snoozeChat(option: string,id) {
   this.SnoozedFor= Number(id)
  const currentMoment = moment();

  if (option === 'pickdatetime') {
    $('.DatepickerModel').show();
  // let datelimit =this.isOpenfordatepicker()
  let MinTime =this.getlimitTme(this.settingsVal.starttime)
  let MaxTime =this.getlimitTme(this.settingsVal.endtime)
//.log(MinTime,MaxTime)
    $('#selctedsnoozeata').datetimepicker({
      dayOfWeekStart: 1,
      lang: 'en',
      minDate: this.getPSTTime(),
      step: 5,
      format: 'm/d/Y H:i',
      formatDate: 'm/d/Y',
      formatTime: 'H:i',
      minTime: new Date(this.getPSTTime()),
      beforeShowDay: (date) => {
       
        const isOpenDay = this.isOpenDayfordatepicker(date);
        var isDisabled = isOpenDay;
        return [!isDisabled, ''];
     
      },
      onChangeDateTime: function(ct, $input) {
        // Handle onChangeDateTime event if needed
      },
      onClose: function(ct, $input) {
        // Handle onClose event if needed
      }
    });
    
      setTimeout(() => {
        this.snoozeInput.nativeElement.focus();
      }, 0);
  } else if (option === 'latertoday') {
    // Set selectedSnoozedate to today at 10 am (PST)
    this.selectedSnoozedate = moment().set({
      hour: 15,
      minute: 0,
      second: 0,
      millisecond: 0
    }).format('YYYY-MM-DD HH:mm:ss');
    this.confirmsnoozeyes();
  } else if (option === 'tomorrow') {
    this.selectedSnoozedate = moment(currentMoment).add(1, 'day').set({
      hour: 8,
      minute: 30,
      second: 0,
      millisecond: 0
    }).format('YYYY-MM-DD HH:mm:ss');
    this.confirmsnoozeyes();
  } else {

  }
 
}
toggleDropdownUserlist(id){
  $('#Insertmenu'+id).show()
}
confirmsnoozeno(){
  $('.confirmsnooze').hide();
  this.selectedSnoozedate =''
}
confirmRemoveSnooze(){
  $('.confirmRemoveSnooze').show();
}
confirmRemoveSnoozeyes(obj){
  //log(obj)
  $('.confirmRemoveSnooze').hide();
  this.spinner.show()
  let data={
    touserid:obj.sn_user_id
    ,
    userid:this.UserId 
  }
  this._api.postTypeRequest('messages/DeleteSnooze',data).subscribe((res:any)=>{
    ////log(res)
    this.spinner.hide()
    if(res.status==1){
      this._api.postTypeRequest('snooze-alert', {userid:this.UserId}).subscribe((res: any) => {});
  ////log("removed success fully")
 // Swal.fire('Success',"Snooze deleted successfully","info")
  this.snoozedataobj={}
  this.GetSnoozeData()
  this.Checkchatandmessage(false)
    }else{
     // Swal.fire('Info!',"Error occured while processing","info")
      ////log("removed failed")
    }
  },err=>{
    this.spinner.hide()
   // Swal.fire('Info!',"Error occured while processing","info")
    ////log("removed err",err)
  })
}
confirmRemoveSnoozeno(){
  $('.confirmRemoveSnooze').hide();
}
confirmsnoozeyes(){
  $('.confirmsnooze').hide();
  this.spinner.show()
  let data={
    touserid:this.SnoozedFor,
    snoozeDate:this.selectedSnoozedate,
    userid:this.UserId 
  }

  this._api.postTypeRequest('messages/InsertSnooze',data).subscribe((res:any)=>{
    ////log(res)
    this.spinner.hide()
    if(res.status==1){
      this._api.postTypeRequest('snooze-alert', {userid:this.UserId}).subscribe((res: any) => {});
  ////log("added success fully")
 // Swal.fire('Success',"Snooze added sucessfully","success")
  this.GetSnoozeData()
  this.Checkchatandmessage(false)
    }else{
      ////log("added failed")
     // Swal.fire('Info!',"Error occured while processing","info")
    }
  },err=>{
    this.spinner.hide()
    ////log("added err",err)
   // Swal.fire('Info!',"Error occured while processing","info")
  })
}


 
updateShowSnoozeLastDayOption() {
  const currentTime = new Date().toLocaleString("en-US", {
    timeZone: "America/Los_Angeles"
  });
 
  const currentHour = new Date(currentTime).getHours();
  this.showSnoozeLastDayOption = currentHour < 12;
}
isPSTDaylightSavingTime(): boolean {
  const currentTime = new Date();
  const currentMonth = currentTime.getMonth() + 1;
  return currentMonth >= 3 && currentMonth <= 11;
}
  
  ngAfterViewInit() {
    //("test1",$); // Should not be undefined
    //("test2",$('#idlemodel')); // Should return the modal element
    
    this.idle.setTimeout(20);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      this.reset();
    });
    
    this.idle.onTimeout.subscribe(() => {
      //('onTimeout triggered');
      $('#idlemodel').show();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.endChat();
    });
    
    this.idle.onIdleStart.subscribe(() => {
      //('onIdleStart triggered');
        this.idleState = 'You\'ve gone idle!'
        
        $('#idlemodel').show();
    });
    
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will be logged out in ' + countdown + ' seconds!'
    });

    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
      if (sessionStorage.getItem('sessionid')) {
        //("watch")
        this.idle.watch()
        this.timedOut = false;
      } else {
        //("stop")
        this.idle.stop();
      }
      try{
        if(this._auth.IsSnooze() =='true'){
          // console.log("Comming as well")
          $("#filterName").val('Snooze')
        }else{
          $("#filterName").val('unreadmsg')
        }
      }catch(e){
        console.log(e)
      }
        this.Checkchatandmessage(true)
      window.addEventListener("message", (event) => {

        if(this._api.WhitelistDomainForpostmessage.indexOf(event.origin)>-1){
          //("comming here", event.origin)
        if(event.data?event.data.from=='user':false){
          //("comming here")
          this.handleMessage();
        }else if(event.data?event.data.from=='Customer':false){
          
          if(this.selectedFriendId==event.data.userid && this.convert_id){
            this.getMessageList(this.selectedFriendId)
          }else{
            if(this.selectedFriendId==event.data.userid){
              this.getMessageList(this.selectedFriendId)
            }
            this.Checkchatandmessage(false)
          }
        }else if(event.data.from =="model-close"){
            this.checkUserStatus()
          
        }else if(event.data.from =="Unread-navigate"){
          // //("this.filtertype",this.filtertype)
         if(this.filtertype !=1){
          $("#filterName").val('unreadmsg')
          this.Checkchatandmessage(true)
         }
        } else if(event.data.from =="Snooze-navigate"){
          // //("this.filtertype",this.filtertype)
         if(this.filtertype !=5){
          $("#filterName").val('Snooze')
          this.Checkchatandmessage(true)
         }
        }else if(event.data.from =="resize"){
          $(".app-front").css("width", event.data.width-30);
        }
      }
     }, false)
     
  }

  userStay(){
    window.parent.postMessage({data:"checkuser",process:"wait"},this._api.ClosemodelreportURL)
    $('#confirmIdel').hide();
  }
  userendChat(){
   
    window.parent.postMessage({data:"checkuser",process:"proceed"},this._api.ClosemodelreportURL)
    this.endChat()
    $('#confirmIdel').hide();

  }
  checkUserStatus(){
    if(sessionStorage.getItem('sessionid')){
    window.parent.postMessage({data:"checkuser",process:"wait"},this._api.ClosemodelreportURL)
    $('#confirmIdel').show();
    }else{
      window.parent.postMessage({data:"checkuser",process:"proceed"},this._api.ClosemodelreportURL)
    }
  }
  settingsVal:any
  ngOnInit() {
    this.updateShowSnoozeLastDayOption()
    this.SockertURL = this._api.baseUrl+'socketwindow';
    var scriptUrl = this._api.baseUrl+'socketwindow';
    window.parent.postMessage({data:"chatapp-pageloaded"},this._api.ClosemodelreportURL)
    let node = document.createElement('iframe');
    node.src = scriptUrl;
    node.className = "d-none";
    document.getElementById('chat-windowTag').appendChild(node);
    // setTimeout(() => {
    //   document.getElementById("SockertURL").setAttribute("src", this.SockertURL)
    // }, 1000);
    this.convert_id = sessionStorage.getItem('sessionid')?sessionStorage.getItem('sessionid'):0;
    this.endsess = 1;
   
     var SessUserData = this._auth.getUserDetails();
    var sessUser = SessUserData[0];
    this.UserId = sessUser.id;
    // console.log('snooze val',this._auth.IsSnooze())
    
    this.loginid = sessUser.userid;
    this.msgType = this._auth.getUsertype() === 'Admin'?0:1;
    this.messages = []; 
    this.GetSnoozeData()

    this._api.getTypeRequest('user/adminusertoken/'+this.loginid).subscribe((res: any) => {
      var settingsVal = this._api.decrypt(res.settings)[0];
      this.settingsVal =this._api.decrypt(res.settings)[0];
      ////log(settingsVal)
      var openTime = this.isOpen(settingsVal.starttime, settingsVal.endtime, settingsVal.timezone);
      var openDay = this.isOpenDay(settingsVal.offdays, settingsVal.timezone);
      //.log(openTime,openDay)
       if(openTime && !openDay){
        this.enablesnooze=true
       }else{
        this.enablesnooze=false
       }
     })

     this.ResetUserList()
  }
  pastTimeArr: any[] = [];
  snoozealertlist:any[]=[]
  SortUserlist(arr) {
    this.snoozealertlist =[]
    const nullArr = [];
    const withTimeArr = [];
    const unOrderArr = arr;
    let updatedArray2 = [];
   let today =new Date().toLocaleString("en-US", {
    timeZone: "America/Los_Angeles"
  });
    unOrderArr.forEach(element => {
      if (element.snoozed_until != null) {
        withTimeArr.push(element);
      }
      if (element.snoozed_until == null) {
        element.showTime = "none";
        nullArr.push(element);
      }
    });
  
    const pastTimeArr = [];
    const timeArr = [];
  
    withTimeArr.forEach(element => {
      const snoozedDate = moment(this.DateFormat(element.snoozed_until)).toDate();
      let OnehourExtend = moment(this.DateFormat(element.snoozed_until)).add(1,'hour').toDate()
      if(moment(today).toDate()>=moment(this.DateFormat(element.snoozed_until)).toDate() && moment(today).toDate()<=OnehourExtend){
        element.showTime = this.minutesdifference(snoozedDate);
        timeArr.push(element);
      }else if(moment(this.DateFormat(element.snoozed_until)).toDate() > moment(today).toDate()){
      
        element.showTime = "pending";
      }else{
        pastTimeArr.push(element);
        element.showTime = "none";
      }
    });
    timeArr.sort((a, b) => new Date(b.snoozed_until).getTime() - new Date(a.snoozed_until).getTime());
    pastTimeArr.sort((a, b) => new Date(b.snoozed_until).getTime() - new Date(a.snoozed_until).getTime());
  
    if (pastTimeArr.length) {
      const objectsAreEqual = (obj1, obj2): boolean => {
        return obj1.id === obj2.id;
      };
  
      for (const obj of pastTimeArr) {
        if (!this.pastTimeArr.some(existingObj => objectsAreEqual(existingObj, obj))) {
          this.pastTimeArr.push(obj);
        }
      }
  
      updatedArray2 = pastTimeArr.filter(item => !this.pastTimeArr.some(existingObj => objectsAreEqual(existingObj, item)));
    } else {
      this.pastTimeArr = pastTimeArr;
      updatedArray2 = this.pastTimeArr;
    }
    let mergedArr = [...pastTimeArr, ...nullArr];
    mergedArr.sort((a, b) => b.mcnt - a.mcnt);
    let result = [...timeArr, ...mergedArr];
    this.UpdatePastSnooze(updatedArray2);
    
    return result;
  }
  SortUserlistFolloption(arr) {
    // //("comming here")
    this.snoozealertlist =[]
    let withTimeArr = [];
    const unOrderArr = arr;
    let updatedArray2 = [];
    let nullArr = [];
   let today =new Date().toLocaleString("en-US", {
    timeZone: "America/Los_Angeles"
  });
    nullArr =unOrderArr.filter(item=>item.snoozed_until ==null)
    nullArr.forEach(element=>{
      element.showTime="none"
    })
    withTimeArr =unOrderArr.filter(item=>item.snoozed_until !=null)

  
    const pastTimeArr = [];
    const timeArr = [];
  
    withTimeArr.forEach(element => {
      const snoozedDate = moment(this.DateFormat(element.snoozed_until)).toDate();
      let OnehourExtend = moment(this.DateFormat(element.snoozed_until)).add(1,'hour').toDate()
      if(moment(today).toDate()>=moment(this.DateFormat(element.snoozed_until)).toDate() && moment(today).toDate()<=OnehourExtend){
        element.showTime = this.minutesdifference(snoozedDate);
        timeArr.push(element);
      }else if(moment(this.DateFormat(element.snoozed_until)).toDate() > moment(today).toDate()){
      
         timeArr.push(element);
        element.showTime = "pending";
      }else{
        pastTimeArr.push(element);
        element.showTime = "none";
      }
    });
    timeArr.sort((a, b) => new Date(b.snoozed_until).getTime() - new Date(a.snoozed_until).getTime());
    pastTimeArr.sort((a, b) => new Date(b.snoozed_until).getTime() - new Date(a.snoozed_until).getTime());
  
    if (pastTimeArr.length) {
      const objectsAreEqual = (obj1, obj2): boolean => {
        return obj1.id === obj2.id;
      };
  
      for (const obj of pastTimeArr) {
        if (!this.pastTimeArr.some(existingObj => objectsAreEqual(existingObj, obj))) {
          this.pastTimeArr.push(obj);
        }
      }
  
      updatedArray2 = pastTimeArr.filter(item => !this.pastTimeArr.some(existingObj => objectsAreEqual(existingObj, item)));
    } else {
      this.pastTimeArr = pastTimeArr;
      updatedArray2 = this.pastTimeArr;
    }
    let resultarray=[...timeArr,...pastTimeArr,...nullArr]
    resultarray= resultarray.sort((a, b) => b.mcnt - a.mcnt)
    this.UpdatePastSnooze(updatedArray2);
 
    return resultarray
  }

 
  minutesdifference(date){
    let today = new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles"
    });
    let date1 =moment(new Date(date), 'YYYY-MM-DD HH:mm:ss')
    let date2=moment(new Date(today), 'YYYY-MM-DD HH:mm:ss') 
    // //(date1,date2)
    let minutesDifference = date2.diff(date1, 'minutes')
    // //(minutesDifference)
    let diffmin =60 -Number(minutesDifference)
    if(Number(diffmin)>=60){
      return '1 hour'
    }else if(Number(diffmin)==1 || Number(diffmin)==0){
     return '1 minute'
    }
    else{
      return diffmin +" minutes"
    }
  }
  UpdatePastSnooze(arr){
  if(arr.length){
    let array =[]
    for(let i =0 ; i<arr.length; i++){
      let obj =arr[i]
      let obj2 ={
      custid : obj.sn_user_id?obj.sn_user_id:obj.id,
      userid :this.UserId
      }
      array.push(obj2)
      if(i+1== arr.length){
        let data={
          Snoozearray:array
        }
        this._api.postTypeRequest("messages/UpdateSnoozeStatus",data).subscribe(res=>{
          
        })
      }
     
    }
  }
  }
  ResetUserList(){
  setInterval(()=>{
  if(this.filtertype==5){
    if(this.snoozelist.length){
    this.snoozelist =this.SortSnoozeList(this.snoozelist)
    }
  }else if(this.filtertype !=4){
    if(this.friendsList2){
    this.friendsList2 = this.SortUserlist(this.AlluserwithoutSort )
    this.friendsList = this.friendsList

    var input, filter;
    input = document.getElementById("serachChatUser")
    filter = input !=null?input.value:''
    if(filter){
     this.searchUser()
    }

  }else if(this.filtertype==4){
   this.friendsList2= this.SortUserlistFolloption(this.AlluserwithoutSort)
   this.friendsList=this.friendsList2
   var input, filter;
    input = document.getElementById("serachChatUser")
    filter = input !=null?input.value:''
    if(filter){
     this.searchUser()
    }
  }
  }
  },60000)
   
  }
  isSnoozeselected:boolean=false
  filtertype:any =1
  AlluserwithoutSort:any=[]
  Checkchatandmessage(isspinner){
    //unread 1, on 2,off 3, sh 4
    
    let filterval =$('#filterName').val()
    if(filterval =='all'){
      this.filtertype = 4
    }else if(filterval =='unreadmsg'){
      this.filtertype = 1
    }else if(filterval =='onlineusers'){
      this.filtertype = 2
    }else if(filterval =='offlineusers'){
      this.filtertype = 3
    }else if(filterval =='Snooze'){
      this.filtertype = 5
    }
    if(isspinner){
    this.spinner.show()
    }
    // console.log("filtertype",this.filtertype)
    if(this.filtertype !=5){
      //log("comming hree")
      this.isSnoozeselected =false
      let tDate =moment(new Date()).add(1,'days').format("L")
      let tommorow = moment(new Date(tDate)).format("YYYY-MM-DD HH:mm:ss")
     //("hitted at-",new Date())
    this._api.getTypeRequest('messages/UserListBYfilter/'+this.filtertype+"/"+this.UserId+'/'+tommorow).subscribe((res:any) => {
      //("response get at-",new Date())
      for(var di=0;di<res.data.length;di++){
        res.data[di].online =res.data[di].online &&res.data[di].online !=null?res.data[di].online :'N'
        // if(res.data[di].mcnt > 0){
        //   $('#msgCnt'+res.data[di].id).show();
        //   $('#msgCnt'+res.data[di].id).html(res.data[di].mcnt);
        // } else{
        //   $('#msgCnt'+res.data[di].id).hide();
        // }
        // if(res.data.length -1==di){
        //   setTimeout(() => {
        //     if(isspinner){
        //     this.spinner.hide()
        //     }
        //   }, 300);
         
        // }
      }
      this.spinner.hide()
      let result 
      //log(res.data)
      this.AlluserwithoutSort =res.data
      if(this.filtertype !=4){
        //("comming here",this.AlluserwithoutSort )
       result  =this.SortUserlist(this.AlluserwithoutSort )
      }else{
        //log("comming here 2")
        //("comming here",this.AlluserwithoutSort )
        result  = this.SortUserlistFolloption(this.AlluserwithoutSort)
        
      }
      //(res.data.length,res.data)
      this.friendsList =result
      this.friendsList2 = result
      
      var input, filter;
       input = document.getElementById("serachChatUser")
       filter = input !=null?input.value:''
       if(filter){
        this.searchUser()
       }
    });
  }else{
    if(isspinner){
    this.isSnoozeselected =true
    this.GetSnoozeData()
    }
  }
  }
  ALLSnoozedList:any=[]
  GetSnoozeData(){
    this.spinner.show()
    let data ={
      type :1,
      userid:this.UserId,
      touserid:0
    }
    this._api.postTypeRequest('messages/GetSnoozeData',data).subscribe((res:any)=>{
      this.spinner.hide()
      if(res.status == 1){
       let result = this.SortSnoozeList(res.data)
       this.ALLSnoozedList=res.data
      this.snoozelength=result.length
      
      this.snoozelist=result
      if(this.ALLSnoozedList.length && this.selectedFriendId){
        let snoozearr=  this.ALLSnoozedList.filter((obj) => {
            return obj.sn_user_id === this.selectedFriendId;
            });
            if(snoozearr.length){
            this.snoozedataobj=snoozearr[0]
            }else{
              this.snoozedataobj={}
            }
         }else{
          this.snoozedataobj={}
         }
      }else{
        ////log(res)
        this.snoozelength=0
        this.snoozelist=[]
        this.ALLSnoozedList=[]
      }
    },err=>{
      this.snoozelength=0
      this.snoozelist=[]
      this.ALLSnoozedList=[]
      this.spinner.hide()
      ////log(err)
    })
  }
  pastSNTimeArr: any[] = [];

  SortSnoozeList(arr) {
    let withTimeArr = arr;
    let pastTimeArr = [];
    let timeArr = [];
    let updatedArray2 = [];
    let today =new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles"
    });
    
    withTimeArr.forEach(element => {
      let OnehourExtend = moment(this.DateFormat(element.snoozed_until)).add(1,'hour').toDate()
      if(moment(today).toDate()>=moment(this.DateFormat(element.snoozed_until)).toDate() && moment(today).toDate()<=OnehourExtend){
      
      }else if(moment(this.DateFormat(element.snoozed_until)).toDate() > moment(today).toDate()){
        timeArr.push(element);
      }else{
        pastTimeArr.push(element);
      }
    });
  
    if (this.pastSNTimeArr.length) {
      const objectsAreEqual = (obj1, obj2): boolean => {
        return obj1.id === obj2.id;
      };
  
      for (const obj of pastTimeArr) {
        if (!this.pastSNTimeArr.some(existingObj => objectsAreEqual(existingObj, obj))) {
          this.pastSNTimeArr.push(obj);
        }
      }
  
      updatedArray2 = pastTimeArr.filter(item => !this.pastSNTimeArr.some(existingObj => objectsAreEqual(existingObj, item)));
    } else {
      this.pastSNTimeArr = pastTimeArr;
      updatedArray2 = this.pastSNTimeArr;
    }

  
    this.UpdatePastSnooze(updatedArray2);
    return timeArr;
  }
  
  AfterChatend(){
    this.convert_id = sessionStorage.getItem('sessionid')?sessionStorage.getItem('sessionid'):0;
      this.endsess = 0;
      this.Checkchatandmessage(true)
  }
  userparent(sfuid){
    window.parent.postMessage({"Method":"CustomerClick","cid":sfuid}, '*')
  }
  handleMessage() {

    if(this.selectedFriendId){
      this.getMessages(this.UserId, this.selectedFriendId);
    }
    this.getRespond(this.selectedFriendId);
    this.convert_id = sessionStorage.getItem('sessionid')?sessionStorage.getItem('sessionid'):0;
    if(sessionStorage.getItem('sessionid'))
      this.endsess = sessionStorage.getItem('sessionid');
  }
  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }
  
  hideChildModal(): void {
    $('#idlemodel').hide();
  }
  
  stay() {
    $('#idlemodel').hide();
    this.reset();
  }
  
  idleLogout() {
    this.endChat()
  }
  isOpen = (openTime, closeTime, timezone) => {
   let Now = new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
    //.log(openTime)
    //.log(Now)
    const now =moment(Now).toDate()
    const [opentargetHour, opentargetMinute] = openTime.split(":").map(Number); 
    const [closetargetHour, closetargetMinute] = closeTime.split(":").map(Number); 
    const currentDate = new Date(); 
    currentDate.setHours(0, 0, 0, 0); 
    const opentargetDate = new Date(Now); 
    const closetargetDate = new Date(Now); 
    opentargetDate.setHours(opentargetHour, opentargetMinute)
    closetargetDate.setHours(closetargetHour, closetargetMinute)
    //.log(opentargetHour,"opentargetDate",opentargetDate)
    //.log("closetargetDate",closetargetDate)
  
  let closetime =moment(closetargetDate).toDate()
  let opentime =moment(opentargetDate).toDate()
 //.log("now:",now)
 //.log("opentime:",opentime)
 //.log("closetime:",closetime)
  if(now>opentime&&now<closetime){
    //.log("comming here true")
   return true
  }else{
    //.log("comming here false")
    return false
  }

    
  }
  isOpenDay = (days, timezone) => {
    const now = moments().tz(timezone);
    var daySplit = days.split(",");
    //log(daySplit.indexOf(now.format('ddd')) !== -1) 
    return daySplit.indexOf(now.format('ddd')) !== -1;
  }
  endChat(){
    $('.confirmmodel').hide();
    $('#idlemodel').hide();
    if(sessionStorage.getItem('sessionid')){
      this._api.getTypeRequest('messages/endchat/'+sessionStorage.getItem('sessionid')).subscribe((res:any) => {
        if(sessionStorage.getItem('customeradmin')){
          sessionStorage.removeItem('sessionid');
          this.idle.stop();
          this.endsess = 0;
          this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {});
          this.AfterChatend();
        } else {
          sessionStorage.clear();
          this._api.getTypeRequest('user/adminusertoken/'+this.loginid).subscribe((res: any) => {
            var settingsVal = this._api.decrypt(res.settings)[0];
            var openTime = this.isOpen(settingsVal.starttime, settingsVal.endtime, settingsVal.timezone);
            var openDay = this.isOpenDay(settingsVal.offdays, settingsVal.timezone);
            // if(openTime && !openDay){
              this.selectedFriendId = 0;
              this.selectedFriendName = '';
              this._auth.setDataInLocalStorage('userData', res.data);  
              this._auth.setDataInLocalStorage('token', res.token); 
              var usrType = this.msgType === 0?'Admin':'User'; 
              this._auth.setDataInLocalStorage('userType', usrType);    
              this._auth.setDataInLocalStorage('timezone', this._api.encryptString(settingsVal.timezone)); 
              this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {});
              this.AfterChatend();
            
          });
        }
      });
    }
    return false;
  }
  endChatfriend(){
    this.messages = [];
    $('.confirmmodelfriendid').hide();
    $('#idlemodel').hide();
    if(sessionStorage.getItem('sessionid')){
      this._api.getTypeRequest('messages/endchat/'+sessionStorage.getItem('sessionid')).subscribe((res:any) => {
        if(sessionStorage.getItem('customeradmin')){
          sessionStorage.removeItem('sessionid');
          this.idle.stop();
          this.selectFriendToChat(parseInt($('#frdID').val()));
          this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {
           });
        }
      });
    }
  }
  endChatConfirm(){
    $('.confirmmodel').show();
  }
  confirmno(){
    $('.confirmmodel').hide();
    $('.confirmmodelfriendid').hide();
  } 
  endalready(){
    $('.alreadymodel').hide();
  }
  getRespond(id){
    this._api.getTypeRequest('message/checkmessages/'+id).subscribe((res:any) => {
      var data = res.data;//this._api.decrypt(res.data);
      if(data.length === 0){
        if(sessionStorage.getItem('sessionid'))
          this.endChat();
      }
    });
  }
  trimedCkEditorText(str) {
    let contentStr = str;
    // Remove Extra whitespaces at the begining of the text
    contentStr = this.trimedCkEditorTextAt(contentStr, true);

    // Remove Extra whitespaces at the end of the text
    contentStr = this.trimedCkEditorTextAt(contentStr, false);

    return contentStr;
}
trimedCkEditorTextAt(contentStr, startOfText) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(contentStr, "text/html")

    // Check first child 
    while(doc.body.children.length) {
        const index = startOfText ? 0 : doc.body.children.length - 1; 
        const child = doc.body.children[index];
        
        if(child.textContent.replace(/\s/g, '').length) {
            // Remove <br> tags
            while(child.children.length) {
                const index = startOfText ? 0 : child.children.length - 1; 
                const grandechild = child.children[index];
                if(grandechild.localName === 'br') grandechild.remove();
                else break;
            }

            // Remove &nbsp;
            const childTextArray = child.innerHTML.split(' ');
            while(childTextArray.length) {
                const index = startOfText ? 0 : childTextArray.length - 1; 
                if(childTextArray[index] === '&nbsp;') childTextArray.splice(index, 1);
                else break;
            }
            child.innerHTML = childTextArray.join(' ');
            break;
        } else {
            child.remove();
        }
    }

    return doc.body.innerHTML;
}
isValidHttpUrl(string) {
  try {
    const url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (err) {
    return false;
  }
}
  gethttpURL(txt){
    var urls = txt.match(/\b(http|https)?(:\/\/)?(\S*)\.(\w{2,4})\b/ig);
    var newStr = txt;
    if(urls && urls.length > 0){
      var txtURL = [];
      for (var i = 0, il = urls.length; i < il; i++) {
          txtURL.push(urls[i]);
      }
      for(var j = 0;j < txtURL.length; j++){
        var url = this.isValidHttpUrl(txtURL[j])?txtURL[j]:'http://'+txtURL[j];
        newStr = newStr.replace(txtURL[j], '<a href="'+url+'" target="_blank">'+txtURL[j]+'</a>');
      }
    }
    return newStr;
  }
  sendMessageClick() {  
    var message = this.message;
    message = this.gethttpURL(message);
    message = this.trimedCkEditorText(message);
        let toUserId = null;
        if (this.selectedFriendId === null) {
            return null;
        }
        const friendData = this.friendsList.filter((obj) => {
          return obj.id == this.selectedFriendId;
        });
          toUserId = this.selectedFriendId;
        var conid = sessionStorage.getItem('sessionid')?sessionStorage.getItem('sessionid'):0;
          if(message){
              var messagePacket = {
                  message:message,
                  fromUserId: this.UserId,
                  toUserId: toUserId,
                  adminid: this.adminId,
                  cid:toUserId,
                  date:this.timzoneTime(),
                  con_id: conid,
              };
               $('#sendloader').show()
              this._api.postTypeRequest('messages/insert', { data:this._api.encryptArray(messagePacket)}).subscribe(async (res: any) => {
                // //("insert",res)
                await this.UpdateUnread(toUserId)
                if(res.already === 0){
                 
                  this.getMessages(this.UserId,toUserId);
                  this.Checkchatandmessage(false)
                  if(toUserId && !sessionStorage.getItem('sessionid')){
                    var data = this._api.decrypt(res.data);
                    this._auth.setDataInLocalStorage('sessionid', data[0].con_id);  
                    this.idle.watch()
                    this.timedOut = false;
                  }
                  this.endsess = 1;
                  this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {});
                  this.message = '';
                  this._api.postThirdparyurl(this._api.notifyTrigger,'').subscribe((res: any) => { });
                } else {
                  $('.alreadymodel').show();
                }
              });
              this.message = '';
          }
        // }else {
        //   //(this.friendsList,this.selectedFriendId)
        //     alert('Unexpected Error Occured,Please contact Admin');
        // }
              
    }
   UpdateUnread(touserid){
      return new Promise(resolve=>{
      this._api.getTypeRequest('message/UpdateUnreadmsg/'+touserid).subscribe((res:any) => {
       resolve(res)
      },err=>{
        resolve(err)
      });
    })
    }
    onMouseEnter(id,type) {
      if(type=='Insert'){
      let arr =this.snoozelist.filter(item=>item.sn_user_id==id)
      //log(id,type,arr)
      if(arr.length==0){
        $('#'+type+id).show() 
      }
      }else{
      $('#'+type+id).show() 
      } 
    }
  
    onMouseLeave(id,type) {
      $('#'+type+id).hide()
      if(type=='Insert'){
        $('#Insertmenu'+id).hide()
      }

    }
  DateFormat(text){
  const datetimestring = text.split("T");
  const date = datetimestring[0]
  const timestring =datetimestring[1]
  const Time = timestring.substring(0,timestring.length-8)
  const dateandtime =date+" "+Time

  return moment(new Date(dateandtime)).format("MM/DD/YYYY HH:mm")
  }
  ChatconvoOpened:Boolean=false
    selectFriendToChat (friendId) {
    
      
    if(!this.selectedFriendId){
      this.endsess=0
    }
      this._api.getTypeRequest('message/adminids/'+this.UserId).subscribe((res:any) => {
        this.adminId = res.data[0].aid;
      });
  
   
      if(sessionStorage.getItem('sessionid') && (this.selectedFriendId && friendId != this.selectedFriendId)){
        $('#frdID').val(friendId);
        $('.confirmmodelfriendid').show();
        return false;
      }else{
        this.messages=[]
        this._api.getTypeRequest('messages/acceptchat/'+friendId+'/'+this.UserId).subscribe((res:any) => {});
      }
          this.endsess = 1;
          const friendData = this.friendsList.filter((obj) => {
          return obj.id === friendId;
          });
          this.selectedUserID = friendData[0]['userid'];
          this.selectedFriendName = friendData[0]['userid']+' '+friendData[0]['firstname'];
          this.selectedFriendId = friendId;
          this.getMessages(this.UserId, friendId);
          const messageBoxContainer = document.getElementById("message-box");
          this.ChatconvoOpened=true
          messageBoxContainer.classList.add("open");
          
     if(this.ALLSnoozedList.length){
    let snoozearr=  this.ALLSnoozedList.filter((obj) => {
        return obj.sn_user_id === friendId;
        });
       
        if(snoozearr.length){
          this.snoozedataobj=snoozearr[0]
          }else{
            this.snoozedataobj={}
          }
     }else{
      this.snoozedataobj={}
     }
     ////log(this.snoozedataobj)
    }
    selectFriendToChatfromSnooze (friendId) {
    
      
      if(!this.selectedFriendId){
        this.endsess=0
      }
        this._api.getTypeRequest('message/adminids/'+this.UserId).subscribe((res:any) => {
          this.adminId = res.data[0].aid;
        });
    
     
        if(sessionStorage.getItem('sessionid') && (this.selectedFriendId && friendId != this.selectedFriendId)){
          $('#frdID').val(friendId);
          $('.confirmmodelfriendid').show();
          return false;
        }else{
          this.messages=[]
          this._api.getTypeRequest('messages/acceptchat/'+friendId+'/'+this.UserId).subscribe((res:any) => {});
        }
            this.endsess = 1;
            const friendData = this.snoozelist.filter((obj) => {
            return obj.sn_user_id === friendId;
            });
            this.selectedUserID = friendData[0]['userid'];
            this.selectedFriendName = friendData[0]['userid']+' '+friendData[0]['firstname'];
            this.selectedFriendId = friendId;
            this.getMessages(this.UserId, friendId);
            this.ChatconvoOpened=true
            const messageBoxContainer = document.getElementById("message-box");
            messageBoxContainer.classList.add("open");
            
       if(this.ALLSnoozedList.length){
      let snoozearr=  this.ALLSnoozedList.filter((obj) => {
          return obj.sn_user_id === friendId;
          });
          if(snoozearr.length){
            this.snoozedataobj=snoozearr[0]
            }else{
              this.snoozedataobj={}
            }
       }else{
        this.snoozedataobj={}
       }
       ////log(this.snoozedataobj)
      }

    getMessageList(friendId){
      const friendData = this.friendsList.filter((obj) => {
        return obj.id === friendId;
        });
        this.selectedUserID = friendData[0]['userid'];
        this.selectedFriendName = friendData[0]['userid']+' '+friendData[0]['firstname'];
        this.selectedFriendId = friendId;
        this.getMessages(this.UserId, friendId); 
    }
    closechat(){
      this.selectedFriendName = '';
      this.ChatconvoOpened=false
      const messageBoxContainer = document.getElementById("message-box");
      messageBoxContainer.classList.remove("open");
    }
    datewiseMessages(data){
      var messArray = [];
        var i=0;
        data.forEach(obj => {
          var dateStr = obj.mdate.split('T')[0];
          if(!messArray[i]){
            messArray[i] =[];
            messArray[i]['date'] = dateStr;
            messArray[i]['data'] = [];
            messArray[i]['data'].push(obj);
          } else if(messArray[i]['date'] === dateStr) {
            messArray[i]['data'].push(obj);
          } else {
            i++;
            messArray[i] =[];
            messArray[i]['date'] = dateStr;
            messArray[i]['data'] = [];
            messArray[i]['data'].push(obj);
          }
        });
        this.scrollToBottom();
        return messArray;
    }
    getMessages(userId, friendId) {
      this._api.getTypeRequest('messages/messages/'+userId+'/'+friendId).subscribe((res:any) => {
        var data = this._api.decrypt(res.data);
        //  //("messages",data)
        // for(var di=0;di<data.length;di++){
        //   data[di].message = this._api.decryptString(data[di].message);
        // }
        this.messages = this.datewiseMessages(data);
        // //(this.UserId)
        //(this.messages)
        if(res.datacnt && res.datacnt[0].unread > 0)
          this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {});
      });
    }
    alignMessage(fromUserId) {
      return fromUserId === 'Admin' ? true : false;
    }
    todayString(mdate){
      var today = new Date();
      var mesdate = new Date(mdate);
      var msdate = new Date(mdate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric'}).replace(/ /g, '/');
      return today.toDateString() === mesdate.toDateString()?'Today':msdate;
    }
    timzoneTime(){
      const givDate = moments().tz(this._auth.getTimezone());
      var mesdate = givDate.format('YYYY-MM-DD H:mm:ss');
      return mesdate;
    }
    scrollToBottom(){
      const messageThread = document.getElementById('users-conversation');
      setTimeout(() => {
        if (messageThread && messageThread.lastElementChild) {
          const lastMessage = messageThread.lastElementChild.querySelector('.message-text:last-child');
          //(lastMessage,messageThread.lastElementChild)
          if (lastMessage && lastMessage.id) {
            const chatelmid = lastMessage.id;
            messageThread.scrollTop = messageThread.scrollHeight + 500; // Ensure thread scrolls to the bottom
            document.getElementById(chatelmid).scrollIntoView(); // Scroll into the last message
          }
        }

      }, 1000);  
      setTimeout(() => {
        $('#sendloader').hide()
      }, 100);
    }
    searchUser() {
      var input, filter;
     input = document.getElementById("serachChatUser")
     filter = input.value.toUpperCase()
     this.friendsList2= this.friendsList.filter(x=>x.firstname.toUpperCase().includes(filter))
     if(this.friendsList2.length==0){
       this.friendsList2= this.friendsList.filter(x=>x.userid.toString().includes(filter))
     }
    
     
   }
   searchUserlist() {
    this.Checkchatandmessage(true)
  }
   searchMessage() {
    var input, filter, ul, li, a, i, j, div;
    for (input = document.getElementById("searchChatMessage"), filter = input.value.toUpperCase(), ul = document.querySelector(".chat-history"), li = ul.getElementsByTagName("li"), i = 0; i < li.length; i++) {
        -1 < li[i].innerText.toUpperCase().indexOf(filter) ? (li[i].style.display = "") : (li[i].style.display = "none");
    }
  }
  getInitials(firstName, lastName) {
    if(!firstName && lastName){
      return lastName[0].toUpperCase()
    }else if(!lastName && firstName){
      return firstName[0].toUpperCase()
    }else if (!firstName ||!lastName) {
      return ''
    }else{
    return (firstName[0] + lastName[0]).toUpperCase();
    }
}
}


