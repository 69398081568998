declare var require: any
import { Component, OnInit,NgModule,AfterViewInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import { ApiService } from './../../../services/api.service'
import { AuthService } from './../../../services/auth.service'
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({  
  selector: 'app-Snooze',
  templateUrl: './snooze-alert.component.html',

})
export class SnoozeComponent implements OnInit,AfterViewInit {
  isLogin: boolean = false
  errorMessage: any;
  UserId:any 
  Snoozelist:any=[]
  constructor(
    private _api: ApiService, 
    private _auth: AuthService,
    private _router:Router,
    public activeRoute: ActivatedRoute
  ) { }
  ngAfterViewInit(): void {
    window.addEventListener("message", (event) => {

      if(this._api.WhitelistDomainForpostmessage.indexOf(event.origin)>-1){
      
      if(event.data?event.data.from=='Snooze':false){
        if(event.data.userid ==this.UserId){

          this.GetSnoozeData()
        }
      }else if (event.data?event.data.from=='Customer':false){
        if(event.data.connected==false){
          window.parent.postMessage({message:"NewMessage",data:event.data.userdata},this._api.ClosemodelreportURL)
        }
      }
    }
  })
  }
  SockertURL:any=''
    ngOnInit(): void {
      this.SockertURL = this._api.baseUrl+'socketwindow';
      var scriptUrl = this._api.baseUrl+'socketwindow';
      let node = document.createElement('iframe');
      node.src = scriptUrl;
      node.className = "d-none";
      document.getElementById('chat-snoozechat').appendChild(node);
      let UserId = this.activeRoute.snapshot.queryParamMap.get('userid')
      // console.log("comming")
    //    
    this.GetUserid(UserId)
       setInterval(()=>{
        // console.log(this.Snoozelist.length)
        if(this.Snoozelist.length){
            this.SortSnoozeList(this.Snoozelist)
        }
       },60000)
      
    }
    GetUserid(userid){
        let data={userid:userid}
        this._api.postTypeRequest('messages/GetUserid',data).subscribe((res:any)=>{
        // console.log(res)
        if(res.status==1){
           this.UserId= res.data[0].id
           this.GetSnoozeData()
        }
        })
    }
    GetSnoozeData(){    let data ={
        type :1,
        userid:this.UserId,
        touserid:0
      }
      this._api.postTypeRequest('messages/GetSnoozeData',data).subscribe((res:any)=>{
        //log("GetSnoozeData",res)
      
        if(res.status == 1){
         let result = this.SortSnoozeList(res.data)
         this.Snoozelist=res.data
        }
      })
    }
    pastSNTimeArr:any=[]
    snoozealertlist:any=[]
    SortSnoozeList(arr) {
        let withTimeArr = arr;
        let pastTimeArr = [];
        let timeArr = [];
        let updatedArray2 =[]
        this.snoozealertlist=[]
        let today =new Date().toLocaleString("en-US", {
          timeZone: "America/Los_Angeles"
        });
        let mins30 =moment(today).add(30,'minutes').toDate()
        withTimeArr.forEach(element => {
        
            //  console.log(moment(mins30).format("MM-DD-YYYY HH:mm") ,moment(this.DateFormat(element.snoozed_until)).format("MM-DD-YYYY HH:mm"))
            if(moment(mins30).format("MM-DD-YYYY HH:mm") ==moment(this.DateFormat(element.snoozed_until)).format("MM-DD-YYYY HH:mm")){
              // console.log("comminge here to alert")
                this.snoozealertlist.push(element)
                // console.log("comming condition alert")
                 }
            timeArr.push(element);
         
        });
      
        if (this.pastSNTimeArr.length) {
          const objectsAreEqual = (obj1, obj2): boolean => {
            return obj1.id === obj2.id;
          };
      
          for (const obj of pastTimeArr) {
            if (!this.pastSNTimeArr.some(existingObj => objectsAreEqual(existingObj, obj))) {
              this.pastSNTimeArr.push(obj);
            }
          }
      
          updatedArray2 = pastTimeArr.filter(item => !this.pastSNTimeArr.some(existingObj => objectsAreEqual(existingObj, item)));
        } else {
          this.pastSNTimeArr = pastTimeArr;
          updatedArray2 = this.pastSNTimeArr;
        }
    
        if(this.snoozealertlist.length){
            window.parent.postMessage({message:"snoozealert",data:this.snoozealertlist},this._api.ClosemodelreportURL)
          }
      }
      DateFormat(text){
        const datetimestring = text.split("T");
        const date = datetimestring[0]
        const timestring =datetimestring[1]
        const Time = timestring.substring(0,timestring.length-8)
        const dateandtime =date+" "+Time
      
        return moment(new Date(dateandtime)).format("MM/DD/YYYY HH:mm")
        }
      UpdatePastSnooze(arr){
        if(arr.length){
          let array =[]
          for(let i =0 ; i<arr.length; i++){
            let obj =arr[i]
            let obj2 ={
            custid : obj.sn_user_id?obj.sn_user_id:obj.id,
            userid :this.UserId
            }
            array.push(obj2)
            if(i+1== arr.length){
              let data={
                Snoozearray:array
              }
              this._api.postTypeRequest("messages/UpdateSnoozeStatus",data).subscribe(res=>{
                
              })
            }
           
          }
        }
        }
}
